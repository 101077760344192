<template>
  <div class="login-page">
    <CardPanel :isReady="isReady" >
      <template v-slot:inputs>
        <div class="errorMessage mb-3 red--text" v-if="loginFailed">The credentials are not valid.</div>
        <input
          v-model="user.email"
          class="input-text email"
          type="text"
          :placeholder="translations.labels.email"
          id="email"
          v-on:keyup.enter="login"
        />
        <input
          v-model="user.password"
          class="input-text password"
          type="password"
          :placeholder="translations.labels.password"
          id="password"
          v-on:keyup.enter="login"
        />
      </template>

      <template v-slot:links>
        <div class="wrp-button">
          <Button-base
            :isFullWidth="true"
            :text="translations.labels.login"
            :onClick="login"
            :type="1"
            center="center"
            :bigger="true"
          />
        </div>

        <Link-base
          :centered="true"
          :text="translations.labels.forgotPassword_link"
          link="/forgot-password"
          :type="1"
        />
      </template>
    </CardPanel>
  </div>
</template>

<script>
import ButtonBase from "@/components/dom/ButtonBase";
import CardPanel from "@/components/panel/CardPanel";
import Component from "vue-class-component";
import LinkBase from "@/components/dom/LinkBase";
import Loader from "@/components/dom/Loader";
import { EventBus } from "@/lib/EventBus";

@Component({
  components: {
    ButtonBase,
    CardPanel,
    LinkBase,
    Loader,
  },
  inject: {
    authService: "authService",
    uiService: "uiService",
  },
  props: {
    title: String,
  },
})
export default class Login {
  isReady = true;
  loginFailed=false;
  user = {
    email: "",
    password: "",
  };
  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }
  async login() {
    this.isReady = false;
    try {
      const _userData = await this.authService.login(this.user);
      localStorage.setItem("currentUser", JSON.stringify(_userData));
      this.$store.commit("SET_CURRENT_USER", _userData);
      EventBus.$emit("reload-menu");
      this.$router.push("/");
    } catch (error) {
      this.loginFailed=true;
    }
    this.isReady = true;
  }
}
</script>
<style lang="scss" scoped>
.login-page {
  background-color: $grey-light;
  width: 100%;
  height: 100%;

  .wrp-button {
    margin-top: 25px;
  }
}
</style>
